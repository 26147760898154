import GeoInput from "components/inputs/GeoInput";
import SimpleInputWithButton from "components/inputs/SimpleInputWithButton";
import { Col, FormGroup } from "reactstrap";
import { TInputProps } from "types/inputProps";
import { INPUT_TYPES } from "./enums";

import { getValue } from "./functions";
import { useFormikContext } from "formik";
import { FormInput } from "modules/form-generator/FormInput";

const generateInput = (props: TInputProps) => {
  switch (props.type) {
    case INPUT_TYPES.NUMBER_WITH_BUTTON:
      return <SimpleInputWithButton {...props} />;
    case INPUT_TYPES.GEO:
      return <GeoInput {...props} />;
  }
};

export const CustomFormInput = (props: TInputProps) => {
  const { size, name, type, conditions } = props;
  const { values } = useFormikContext();

  const checkConditions = () => {
    if (conditions) {
      let flag = false;
      conditions.forEach((item) => {
        const value = getValue(values, item.toCompare);
        if (value?.toString() === item.acceptValue) {
          flag = true;
          return;
        }
      });
      return flag;
    } else {
      return true;
    }
  };

  if (type === INPUT_TYPES.GEO || type === INPUT_TYPES.NUMBER_WITH_BUTTON) {
    return (
      <>
        {checkConditions() && (
          <Col md={size ? size : 6} key={name} className="my-auto">
            <FormGroup>{generateInput(props)}</FormGroup>
          </Col>
        )}
      </>
    );
  } else {
    return <FormInput {...props} />;
  }
};
