import { ErrorMessage, FastField, Field } from "formik";
import { getError } from "../validators";
import { Label } from "reactstrap";
import { TInputProps } from "../types/inputProps";
import Tooltip from "../../../components/tooltip/Tooltip";

const NumberInput = ({
  name,
  type,
  disabled,
  label,
  depend,
  hidden,
  shouldUpdate,
  tooltipText,
  step,
}: TInputProps) => {
  const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {};

  return (
    <div className="w-100">
      <Label className="sfa-form__label">
        {label}

        {tooltipText !== undefined && (
          <Tooltip text={tooltipText || ""}>
            <div className="form-tootltip">i</div>
          </Tooltip>
        )}
      </Label>
      {depend ? (
        <Field
          component={CustomNumberInput}
          name={name}
          {...shoudUpdateProp}
          disabled={disabled}
          step={step}
          type={hidden ? "hidden" : type}
        />
      ) : (
        <FastField
          component={CustomNumberInput}
          name={name}
          {...shoudUpdateProp}
          disabled={disabled}
          step={step}
          type={hidden ? "hidden" : type}
        />
      )}
    </div>
  );
};

export default NumberInput;

const CustomNumberInput = ({
  field,
  form: { touched, errors },
  ...props
}: any) => {
  const preventInvalidCharacters = (event: any) => {
    ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();
  };

  return (
    <div
      className={
        getError(field, touched, errors) ? "sfa-form__input--error" : ""
      }
    >
      <input
        className="form-control"
        min={0}
        onKeyPress={preventInvalidCharacters}
        {...field}
        {...props}
      />
      <div className="sfa-form__error">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};
