import { INPUT_TYPES } from "helper/enums";
import { URLS, REGISTRATION_FORM_URL } from "./urls";

export const COMPANY_INFO = {
  firstPart: [
    {
      name: "nip",
      type: INPUT_TYPES.NUMBER_WITH_BUTTON,
      label: "FORM_PDF.FORM.COMPANY_INFO.NIP.LABEL",
      fields: [
        { api: "regon", goal: "regon" },
        { api: "companyName", goal: "companyName" },
        // {api: "voivodeship", goal: "registeredAddress_state"},
        { api: "zipCode", goal: "registeredAddress.postCode" },
        { api: "houseNumber", goal: "registeredAddress.houseNumber" },
        { api: "flatNumber", goal: "registeredAddress.localNumber" },
        { api: "voivodeship", goal: "registeredAddress.state", isSelect: true },
        { api: "county", goal: "registeredAddress.province", isSelect: true },
        { api: "community", goal: "registeredAddress.commune", isSelect: true },
        { api: "city", goal: "registeredAddress.city", isSelect: true },
        { api: "street", goal: "registeredAddress.street", isSelect: true },
        { api: "krs", goal: "krs" },
        { api: "districtCourt", goal: "court" },
        { api: "districtCourtDepartment", goal: "courtDepartment" },
      ],
      fieldsToTouch: ["capital"],
      requestLimit: 3,
      depend: true,
      url: `${
        process.env.REACT_APP_REGON_API || `${REGISTRATION_FORM_URL}/regon-api/`
      }company/data?nip=`,
      text: {
        tooltipIfEnable: "FORM_PDF.FORM.COMPANY_INFO.NIP.TOOLTIP.ENABLE",
        tooltipIfRequestLimit: "FORM_PDF.FORM.COMPANY_INFO.NIP.TOOLTIP.LIMIT",
        tooltipIfDisabled: "FORM_PDF.FORM.COMPANY_INFO.NIP.TOOLTIP.DISABLE",
        button: "FORM_PDF.FORM.COMPANY_INFO.NIP.BUTTON",
      },
    },
    {
      name: "regon",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.REGON.LABEL",
    },
    {
      name: "companyName",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.NAME.LABEL",
      size: 12,
    },
  ],
  secondPart: [
    {
      name: "registeredAddress.state",
      data: "voivodeships",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.STATE.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.STATE.LABEL",
      options: [],
      clearList: [
        "registeredAddress.province",
        "registeredAddress.commune",
        "registeredAddress.city",
        "registeredAddress.street",
      ],
    },
    {
      name: "registeredAddress.province",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.PROVINCE.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.PROVINCE.LABEL",
      options: [],
      depend: true,
      url: URLS.counties,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "registeredAddress.state",
        },
      ],
      dependName: "registeredAddress.state",
      apiValueName: "id",
      apiLabelName: "name",
      clearList: [
        "registeredAddress.commune",
        "registeredAddress.city",
        "registeredAddress.street",
      ],
      paramsList: [
        {
          name: "registeredAddress.state",
          value: "voivedeshipId",
        },
      ],
    },
    {
      name: "registeredAddress.commune",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
      options: [],
      depend: true,
      url: URLS.communities,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "registeredAddress.state",
        },
        {
          paramName: "countyId",
          dependFieldName: "registeredAddress.province",
        },
      ],
      dependName: "registeredAddress.province",
      apiValueName: "id",
      apiLabelName: "name",
      clearList: ["registeredAddress.city", "registeredAddress.street"],
      paramsList: [
        {
          name: "registeredAddress.state",
          value: "voivedeshipId",
        },
        {
          name: "registeredAddress.province",
          value: "countyId",
        },
      ],
    },
    {
      name: "registeredAddress.city",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.CITY.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.CITY.PLACEHOLDER",
      options: [],
      depend: true,
      url: URLS.cities,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "registeredAddress.state",
        },
        {
          paramName: "countyId",
          dependFieldName: "registeredAddress.province",
        },
        {
          paramName: "communityId",
          dependFieldName: "registeredAddress.commune",
        },
      ],
      dependName: "registeredAddress.commune",
      apiValueName: "id",
      apiLabelName: "name",
      apiTypeName: "type",
      clearList: ["registeredAddress.street"],
      paramsList: [
        {
          name: "registeredAddress.state",
          value: "voivedeshipId",
        },
        {
          name: "registeredAddress.province",
          value: "countyId",
        },
        {
          name: "registeredAddress.commune",
          value: "communityId",
        },
      ],
    },
    {
      name: "registeredAddress.postCode",
      type: INPUT_TYPES.POST_CODE,
      label: "FORM_PDF.FORM.COMPANY_INFO.POST_CODE.LABEL",
    },
    {
      name: "registeredAddress.street",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.STREET.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.STREET.LABEL",
      options: [],
      depend: true,
      size: 12,
      url: URLS.streets,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "registeredAddress.state",
        },
        {
          paramName: "countyId",
          dependFieldName: "registeredAddress.province",
        },
        {
          paramName: "communityId",
          dependFieldName: "registeredAddress.commune",
        },
        {
          paramName: "cityId",
          dependFieldName: "registeredAddress.city",
        },
      ],
      dependName: "registeredAddress.city",
      apiValueName: "id",
      apiLabelName: "name",
      paramsList: [
        {
          name: "registeredAddress.state",
          value: "voivedeshipId",
        },
        {
          name: "registeredAddress.province",
          value: "countyId",
        },
        {
          name: "registeredAddress.commune",
          value: "communityId",
        },
        {
          name: "registeredAddress.city",
          value: "cityId",
        },
      ],
    },
    {
      name: "registeredAddress.houseNumber",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.HOUSE_NUMBER.LABEL",
    },
    {
      name: "registeredAddress.localNumber",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.LOCAL_NUMBER.LABEL",
    },
  ],
  thirdPart: [
    {
      name: "companyAddress.sameThenCompanyAddress",
      type: INPUT_TYPES.SWITCH,
      label: "FORM_PDF.FORM.COMPANY_INFO.OTHER_ADDRESS.LABEL",
      size: 12,
    },
    {
      name: "companyAddress.state",
      data: "voivodeships",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.STATE.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.STATE.LABEL",
      options: [],
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
      depend: true,
      clearList: [
        "companyAddress.province",
        "companyAddress.commune",
        "companyAddress.city",
        "companyAddress.street",
      ],
    },
    {
      name: "companyAddress.province",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.PROVINCE.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.PROVINCE.LABEL",
      options: [],
      depend: true,
      url: URLS.counties,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "companyAddress.state",
        },
        {
          paramName: "countyId",
          dependFieldName: "companyAddress.province",
        },
      ],
      dependName: "companyAddress.state",
      apiValueName: "id",
      apiLabelName: "name",
      clearList: [
        "companyAddress.commune",
        "companyAddress.city",
        "companyAddress.street",
      ],
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
      paramsList: [
        {
          name: "companyAddress.state",
          value: "voivedeshipId",
        },
      ],
    },
    {
      name: "companyAddress.commune",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
      options: [],
      depend: true,
      url: URLS.communities,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "companyAddress.state",
        },
        {
          paramName: "countyId",
          dependFieldName: "companyAddress.province",
        },
      ],
      dependName: "companyAddress.province",
      apiValueName: "id",
      apiLabelName: "name",
      clearList: ["companyAddress.city", "companyAddress.street"],
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
      paramsList: [
        {
          name: "companyAddress.state",
          value: "voivedeshipId",
        },
        {
          name: "companyAddress.province",
          value: "countyId",
        },
      ],
    },
    {
      name: "companyAddress.city",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.CITY.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.CITY.PLACEHOLDER",
      options: [],
      depend: true,
      url: URLS.cities,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "companyAddress.state",
        },
        {
          paramName: "countyId",
          dependFieldName: "companyAddress.province",
        },
        {
          paramName: "communityId",
          dependFieldName: "companyAddress.commune",
        },
      ],
      dependName: "companyAddress.commune",
      apiValueName: "id",
      apiLabelName: "name",
      apiTypeName: "type",
      clearList: ["companyAddress.street"],
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
      paramsList: [
        {
          name: "companyAddress.state",
          value: "voivedeshipId",
        },
        {
          name: "companyAddress.province",
          value: "countyId",
        },
        {
          name: "companyAddress.commune",
          value: "communityId",
        },
      ],
    },
    {
      name: "companyAddress.postCode",
      type: INPUT_TYPES.POST_CODE,
      label: "FORM_PDF.FORM.COMPANY_INFO.POST_CODE.LABEL",
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
    },
    {
      name: "companyAddress.street",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "FORM_PDF.FORM.COMPANY_INFO.STREET.LABEL",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.STREET.LABEL",
      options: [],
      size: 12,
      depend: true,
      url: URLS.streets,
      dependNames: [
        {
          paramName: "voivedeshipId",
          dependFieldName: "companyAddress.state",
        },
        {
          paramName: "countyId",
          dependFieldName: "companyAddress.province",
        },
        {
          paramName: "communityId",
          dependFieldName: "companyAddress.commune",
        },
        {
          paramName: "cityId",
          dependFieldName: "companyAddress.city",
        },
      ],
      dependName: "companyAddress.city",
      apiValueName: "id",
      apiLabelName: "name",
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
      paramsList: [
        {
          name: "companyAddress.state",
          value: "voivedeshipId",
        },
        {
          name: "companyAddress.province",
          value: "countyId",
        },
        {
          name: "companyAddress.commune",
          value: "communityId",
        },
        {
          name: "companyAddress.city",
          value: "cityId",
        },
      ],
    },
    {
      name: "companyAddress.houseNumber",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.HOUSE_NUMBER.LABEL",
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
    },
    {
      name: "companyAddress.localNumber",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.LOCAL_NUMBER.LABEL",
      conditions: [
        {
          toCompare: "companyAddress.sameThenCompanyAddress",
          acceptValue: "false",
        },
      ],
    },
  ],
  fourthPart: [
    {
      name: "krs",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.KRS.LABEL",
    },
    {
      name: "capital",
      type: INPUT_TYPES.NUMBER,
      step: "0.01",
      label: "FORM_PDF.FORM.COMPANY_INFO.CAPITAL.LABEL",
    },
    {
      name: "court",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.COURT.LABEL",
      size: 12,
    },
    {
      name: "courtDepartment",
      type: INPUT_TYPES.TEXT,
      label: "FORM_PDF.FORM.COMPANY_INFO.COURT_DEPARTMENT.LABEL",
      size: 12,
    },
  ],
};
