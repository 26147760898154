export const RepresentativeObject = {
  address: {
    state: null,
    province: null,
    commune: null,
    city: null,
    postCode: "",
    street: null,
    houseNumber: "",
    localNumber: "",
  },
  firstName: "",
  lastName: "",
  identityCardNumber: "",
  phone: "",
  email: "",
  pesel: "",
  position: "",
};

export const initialValues = {
  companyType: null,
  nip: "",
  regon: "",
  companyName: "",
  registeredAddress: {
    state: null,
    province: null,
    commune: null,
    city: null,
    postCode: "",
    houseNumber: "",
    localNumber: "",
  },
  // registeredAddress_state: null,
  // registeredAddress_province: null,
  // registeredAddress_commune: null,
  // registeredAddress_city: null,
  // registeredAddress_postCode: "",
  // registeredAddress_houseNumber: "",
  // registeredAddress_localNumber: "",
  representatives: [RepresentativeObject],
  companyAddress: {
    sameThenCompanyAddress: true,
    state: "",
    province: "",
    commune: "",
    city: "",
    postCode: "",
    houseNumber: "",
    localNumber: "",
  },
  // companyAddress.state: "",
  // companyAddress_province: "",
  // companyAddress_commune: "",
  // companyAddress_city: "",
  // companyAddress_postCode: "",
  // companyAddress_houseNumber: "",
  // companyAddress_localNumber: "",
  krs: "",
  capital: "",
  court: "",
  courtDepartment: "",
  point: {
    address: {
      sameThenCompanyAddress: false,
      state: null,
      province: null,
      commune: null,
      city: null,
      postCode: "",
      street: null,
      houseNumber: "",
      localNumber: "",
    },
    partnerType: null,
    partner: null,
    gps: "",
    organizationType: "",
    retailSpace: "",
    gameSpace: false,
    //enterToLocal: {label: "", value: ""},
    peopleCount: "",
    //function: {label: "", value: ""},
  },
  hours: {
    mon_fry: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
    other: false,
    monday: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
    tuesday: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
    wednesday: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
    thursday: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
    friday: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
    saturday: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
    sunday: {
      action: "IN_HOURS",
      from: "8:00",
      to: "22:00",
    },
  },
  // point_state: null,
  // point_province: null,
  // point_commune: null,
  // point_city: null,
  // point_postCode: "",
  // point_street: null,
  // point_houseNumber: "",
  // point_localNumber: "",
  // point_GPS: "",
  // point_organizationType: "",
  // point_retailSpace: "",
  // point_gameSpace: "",
  // point_enterToLocal: "",
  // point_peopleCount: "",
  // point_mon_fry_from: "",
  // point_mon_fry_to: "",
  // point_mon_fry_all: false,
  // point_saturday_from: "",
  // point_saturday_to: "",
  // point_saturday_all: false,
  // point_sunday_from: "",
  // point_sunday_to: "",
  // point_sunday_all: false,
  // point_function: "",
  approvement: {
    infoPackage: false,
    rodo: false,
    // instalation: false,
    // infoApproval: false,
    // autorization: false,
    // statments: false,
    // taxStatments: false,
    // game: false,
    // warrnig: false,
  },
  geo: {
    state: null,
    province: null,
    commune: null,
    city: null,
    street: null,
    houseNumber: "",
  },
  // approvement_infoPackage: false,
  // approvement_instalation: false,
  // approvement_infoApproval: false,
  // approvement_autorization: false,
  // approvement_statments: false,
  // approvement_taxStatments: false,
  // approvement_GAME: false,
  // approvement_WARRNIG: false,
};
