import Logo from "./Logo";
import program from "./program_wspoldzialania.png";
import responsibility from "./responsibility.png";
import plus18 from "./plus18.png";
import wla from "./wla.png";
import european_lotteries from "./european_lotteries.png";
import { Tab } from "layouts/TSLayout";
import { MAIN_TS_URL } from "config/registration-form/urls";

type FooterProps = {
  tabs: Tab[];
};

const Footer = ({ tabs }: FooterProps) => {
  return (
    <div>
      <footer className="footer">
        <div className="cont">
          <section>
            <div className="footer-menu-totalizator">
              <ul className="first-level">
                {tabs.map((tab) => (
                  <li key={tab.id}>
                    <p
                      onClick={() => {
                        if (tab.items.length === 0) {
                          window.location.href = `${MAIN_TS_URL}${tab.url}`;
                        }
                      }}
                      className={`first-level-link ${
                        tab.items.length === 0 ? "cursor-pointer" : ""
                      }`}
                    >
                      {tab.name}
                    </p>
                    <div className="menu-second-level-wrapper">
                      <ul className="second-level">
                        {tab.items.map((item) => (
                          <li key={item.id}>
                            <a href={`${MAIN_TS_URL}${item.href}`}>
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <div className="lower-footer-wrapper">
            <div className="left-side">
              <a href="/">
                <Logo />
              </a>
              <div>
                <p className="text-white">
                  © 2024 Totalizator Sportowy.
                  <span className="text-nowrap">
                    {` Wszelkie prawa zastrzeżone.`}
                  </span>
                </p>
                <section>
                  <div className="footer-bottom-menu">
                    <ul>
                      <li>
                        <a href={`${MAIN_TS_URL}/polityka-cookies`}>
                          Polityka cookies
                        </a>
                      </li>
                      <li>
                        <a href={`${MAIN_TS_URL}/regulamin-serwisu`}>
                          Regulamin Serwisu
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            </div>
            <div className="right-side">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://torsluzewiec.pl/"
                className="footer-link"
              >
                <img
                  loading="lazy"
                  src={program}
                  alt="Tor Służewiec"
                  className="tor-sluzewiec-icon"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${MAIN_TS_URL}/zaangazowanie/csr`}
                className="footer-link"
              >
                <img
                  loading="lazy"
                  src={responsibility}
                  alt="Odpowiedzialność"
                  className="graj-odpowiedzialnie-icon"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${MAIN_TS_URL}/zaangazowanie/csr`}
                className="footer-link"
              >
                <img
                  loading="lazy"
                  src={plus18}
                  alt="Gra dla pełnoletnich"
                  className="ok-18-icon"
                />
              </a>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.world-lotteries.org/"
                  className="footer-link"
                >
                  <img
                    loading="lazy"
                    src={wla}
                    alt="World Lottery Association"
                    className="wla-icon"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.european-lotteries.org/"
                  className="footer-link"
                >
                  <img
                    loading="lazy"
                    src={european_lotteries}
                    alt="The European Lotteries"
                    className="eul-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
