import { INPUT_TYPES } from "helper/enums";
import { URLS } from "./urls";

export const getHoursOptions = () => {
  let array = [];
  for (let i = 0; i < 49; i++) {
    array.push({
      label: `${Math.floor(i / 2)}:${i % 2 === 1 ? "30" : "00"}`,
      value: `${Math.floor(i / 2)}:${i % 2 === 1 ? "30" : "00"}`,
    });
  }
  return array;
};

export const POINT_INFO = [
  {
    name: "point.address.sameThenCompanyAddress",
    type: INPUT_TYPES.SWITCH,
    label: "FORM_PDF.FORM.POINT_INFORMATION.SAME_ADDRESS.LABEL",
    size: 12,
  },
  {
    name: "point.address.state",
    data: "voivodeships",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.STATE.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.STATE.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    clearList: [
      "point.address.province",
      "point.address.commune",
      "point.address.city",
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
    //depend: true
  },
  {
    name: "point.address.province",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.PROVINCE.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.PROVINCE.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: URLS.counties,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "point.address.state",
      },
    ],
    dependName: "point.address.state",
    apiValueName: "id",
    apiLabelName: "name",
    clearList: [
      "point.address.commune",
      "point.address.city",
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
    paramsList: [
      {
        name: "point.address.state",
        value: "voivedeshipId",
      },
    ],
  },
  {
    name: "point.address.commune",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    placeholder: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: URLS.communities,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "point.address.state",
      },
      {
        paramName: "countyId",
        dependFieldName: "point.address.province",
      },
    ],
    dependName: "point.address.province",
    apiValueName: "id",
    apiLabelName: "name",
    clearList: [
      "point.address.city",
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
    paramsList: [
      {
        name: "point.address.state",
        value: "voivedeshipId",
      },
      {
        name: "point.address.province",
        value: "countyId",
      },
    ],
  },
  {
    name: "point.address.city",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.CITY.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.CITY.PLACEHOLDER",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: URLS.cities,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "point.address.state",
      },
      {
        paramName: "countyId",
        dependFieldName: "point.address.province",
      },
      {
        paramName: "communityId",
        dependFieldName: "point.address.commune",
      },
    ],
    dependName: "point.address.commune",
    apiValueName: "id",
    apiLabelName: "name",
    apiTypeName: "type",
    clearList: [
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
    paramsList: [
      {
        name: "point.address.state",
        value: "voivedeshipId",
      },
      {
        name: "point.address.province",
        value: "countyId",
      },
      {
        name: "point.address.commune",
        value: "communityId",
      },
    ],
  },
  {
    name: "point.address.postCode",
    type: INPUT_TYPES.POST_CODE,
    label: "FORM_PDF.FORM.POINT_INFORMATION.POST_CODE.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    // depend: true
  },
  {
    name: "point.address.street",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.STREET.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.STREET.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: URLS.streets,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "point.address.state",
      },
      {
        paramName: "countyId",
        dependFieldName: "point.address.province",
      },
      {
        paramName: "communityId",
        dependFieldName: "point.address.commune",
      },
      {
        paramName: "cityId",
        dependFieldName: "point.address.city",
      },
    ],
    dependName: "point.address.city",
    apiValueName: "id",
    apiLabelName: "name",
    clearList: [
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
    paramsList: [
      {
        name: "point.address.state",
        value: "voivedeshipId",
      },
      {
        name: "point.address.province",
        value: "countyId",
      },
      {
        name: "point.address.commune",
        value: "communityId",
      },
      {
        name: "point.address.city",
        value: "cityId",
      },
    ],
  },
  {
    name: "point.address.houseNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.HOUSE_NUMBER.LABEL",
    clearList: ["point.address.localNumber|string", "point.gps|string"],
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    //depend: true
  },
  {
    name: "point.address.localNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.LOCAL_NUMBER.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    //depend: true
  },
  // {
  //   name: "point.gps",
  //   type: INPUT_TYPES.GEO,
  //   depend: true,
  //   disabled: true,
  //   conditions: [
  //     {
  //       toCompare: "point.address.sameThenCompanyAddress",
  //       acceptValue: "false",
  //     },
  //   ],
  //   label: "",
  //   data: [
  //     "point.address.state",
  //     "point.address.province",
  //     "point.address.commune",
  //     "point.address.city",
  //     "point.address.postCode",
  //     "point.address.street",
  //     "point.address.houseNumber",
  //   ],
  // },
  {
    name: "point.organizationType",
    type: INPUT_TYPES.CREATABLE_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.POINT_TYPE.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.POINT_TYPE.LABEL",
    options: [
      { label: "HANDLOWA", value: "HANDLOWA" },
      { label: "USŁUGOWA", value: "USŁUGOWA" },
    ],
  },
  {
    name: "point.retailSpace",
    type: INPUT_TYPES.NUMBER,
    label: "FORM_PDF.FORM.POINT_INFORMATION.RETAIL_SPACE.LABEL",
  },
  {
    name: "point.gameSpace",
    type: INPUT_TYPES.SWITCH,
    label: "FORM_PDF.FORM.POINT_INFORMATION.GAME_SPACE.LABEL",
  },
  // {
  //     name: 'point.enterToLocal',
  //     type: INPUT_TYPES.CREATABLE_SELECT,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.ENTER.LABEL",
  //     placeholder: "FORM_PDF.FORM.POINT_INFORMATION.ENTER.LABEL",
  //     options: [
  //         { label: "Lokal wolnostojący bez własnego wejścia (Okienko)", value: "Lokal wolnostojący bez własnego wejścia (Okienko)" },
  //         { label: "Lokal wolnostojący z własnym wejściem z ulicy", value: "Lokal wolnostojący z własnym wejściem z ulicy" },
  //         { label: "Lokal na terenie innego obiektu posiadający własne wejście wewnątrz", value: "Lokal na terenie innego obiektu posiadający własne wejście wewnątrz" },
  //         { label: "Odrębne stoisko bez własnego wejścia, działające na obszarze innego sklepu lub obiektu, np. wyspa, okienko", value: "Odrębne stoisko bez własnego wejścia, działające na obszarze innego sklepu lub obiektu, np. wyspa, okienko" }
  //     ]
  // },
  {
    name: "point.peopleCount",
    type: INPUT_TYPES.NUMBER,
    label: "FORM_PDF.FORM.POINT_INFORMATION.PERSON.LABEL",
  },
  {
    name: "point.partnerType",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.PRELIMINARY_INFO.PARTNER_TYPE.LABEL",
    placeholder: "FORM_PDF.FORM.PRELIMINARY_INFO.PARTNER_TYPE.PLACEHOLDER",
    options: [
      { label: "Prawny", value: "LEGAL" },
      { label: "Sieciowy", value: "NETWORK" },
    ],
    clearList: ["point.partner"],
  },
  {
    name: "point.partner",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.PRELIMINARY_INFO.PARTNER.LABEL",
    placeholder: "FORM_PDF.FORM.PRELIMINARY_INFO.PARTNER.PLACEHOLDER",
    options: [
      { label: "Pakiet_S", value: "Pakiet_S" },
      { label: "Model wrocławski", value: "Model wrocławski" },
      { label: "iLotto", value: "iLotto" },
      { label: "Fizyczna bez ZUZP", value: "Fizyczna bez ZUZP" },
      { label: "Orlen", value: "Orlen" },
      { label: "Ruch", value: "Ruch" },
      { label: "Kolporter", value: "Kolporter" },
      { label: "Cirkle", value: "Cirkle" },
      { label: "Poczta Polska", value: "Poczta Polska" },
      { label: "LTR", value: "LTR" },
      { label: "GS", value: "GS" },
      { label: "Shell", value: "Shell" },
      { label: "Tabak Polska", value: "Tabak Polska" },
      { label: "Polo Market", value: "Polo Market" },
      { label: "Carrefour", value: "Carrefour" },
      { label: "Lukoil", value: "Lukoil" },
      { label: "Real", value: "Real" },
      { label: "Społem", value: "Społem" },
      { label: "Lotos", value: "Lotos" },
      { label: "Żabka", value: "Żabka" },
      { label: "BP Express", value: "BP Express" },
      { label: "HoReCa", value: "HoReCa" },
      { label: "Żabka-500", value: "Żabka-500" },
      { label: "BillBird", value: "BillBird" },
      { label: "Szorti", value: "Szorti" },
      { label: "Totolotek", value: "Totolotek" },
      { label: "E-TOTO", value: "E-TOTO" },
      { label: "E-TOTO Ajent", value: "E-TOTO Ajent" },
      { label: "RUCH S.A.", value: "RUCH S.A." },
      { label: "BP Europa SE", value: "BP Europa SE" },
      { label: "AMIC", value: "AMIC" },
      { label: "Dayli Polska", value: "Dayli Polska" },
      { label: "Totolotek Ajent", value: "Totolotek Ajent" },
      { label: "BillBird S.A.", value: "BillBird S.A." },
      { label: "ZabkaAgent", value: "ZabkaAgent" },
      { label: "Ledi", value: "Ledi" },
      { label: "INMEDIO", value: "INMEDIO" },
      { label: "Świat Prasy", value: "Świat Prasy" },
      { label: "MILA", value: "MILA" },
      { label: "Media Press", value: "Media Press" },
      { label: "Brak zwrotów", value: "Brak zwrotów" },
      { label: "SW Hybryda", value: "SW Hybryda" },
      { label: "Kolporter Agent", value: "Kolporter Agent" },
      { label: "LOTOS (DOFO)", value: "LOTOS (DOFO)" },
      { label: "WATIS", value: "WATIS" },
      { label: "Nasz Sklep", value: "Nasz Sklep" },
      { label: "Livio", value: "Livio" },
      { label: "Rabat Detal", value: "Rabat Detal" },
      { label: "Duży Ben Sp. z o.o.", value: "Duży Ben Sp. z o.o." },
      { label: "MORTH PRESS", value: "MORTH PRESS" },
    ],
    conditions: [
      {
        toCompare: "point.partnerType",
        acceptValue: "NETWORK",
      },
    ],
  },
  // {
  //     name: "point.mon_fry",
  //     type: INPUT_TYPES.TIME_RANGE,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.MON_FRY",
  //     size: 4,
  //     options: getHoursOptions()
  // },
  // {
  //     name: "point.saturday",
  //     type: INPUT_TYPES.TIME_RANGE,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SATURDAY",
  //     size: 4,
  //     options: getHoursOptions()
  // },
  // {
  //     name: "point.sunday",
  //     type: INPUT_TYPES.TIME_RANGE,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SUNDAY",
  //     size: 4,
  //     options: getHoursOptions()
  // },
  // {
  //     name: 'point.function',
  //     type: INPUT_TYPES.CREATABLE_SELECT,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.POINT_FUNCTION.LABEL",
  //     placeholder: "FORM_PDF.FORM.POINT_INFORMATION.POINT_FUNCTION.LABEL",
  //     options: [
  //         { label: "Sklep wielkopowierzchniowy - powyżej 500 m2", value: "Sklep wielkopowierzchniowy - powyżej 500 m2" },
  //         { label: "Duży sklep / Supermarket - 200 m2 - 500 m2", value: "Duży sklep / Supermarket - 200 m2 - 500 m2" },
  //         { label: "Średni sklep - 50 - 200 m2", value: "Średni sklep - 50 - 200 m2" },
  //         { label: "Mały sklep - do 50 m2", value: "Mały sklep - do 50 m2" },
  //         { label: "Salonik prasowy / Kiosk", value: "Salonik prasowy / Kiosk" },
  //         { label: "Punkt usługowy", value: "Punkt usługowy" },
  //         { label: "Stacja benzynowa", value: "Stacja benzynowa" }
  //     ]
  // }
];

export const POINT_HOURS_INFO = {
  part1: [
    {
      name: "hours.mon_fry",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.MON_FRY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "false" }],
    },
    {
      name: "hours.other",
      size: 12,
      type: INPUT_TYPES.SWITCH,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.OTHER",
    },
    {
      name: "hours.monday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.MONDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.tuesday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.TUESDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.wednesday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.WEDNESDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.thursday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.THURSDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.friday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.FRIDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
  ],
  part2: [
    {
      name: "hours.saturday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SATURDAY",
      size: 12,
      options: getHoursOptions(),
    },
    {
      name: "hours.sunday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SUNDAY",
      size: 12,
      options: getHoursOptions(),
    },
  ],
};
