import { INPUT_TYPES } from "helper/enums";
import { URLS } from "./urls";

export const REPRESENTATIVE = [
  {
    name: "firstName",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.FIRSTNAME.LABEL",
  },
  {
    name: "lastName",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.LASTNAME.LABEL",
  },
  {
    name: "phone",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.PHONE_NUMBER.LABEL",
  },
  {
    name: "email",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.EMIAL.LABEL",
  },
  {
    name: "pesel",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.PESEL.LABEL",
    conditions: [
      { toCompare: "companyType.value", acceptValue: "JDG" },
      { toCompare: "companyType.value", acceptValue: "CIVIL" },
    ],
  },
  {
    name: "identityCardNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.ID_CARD_NUMBER.LABEL",
    conditions: [
      { toCompare: "companyType.value", acceptValue: "JDG" },
      { toCompare: "companyType.value", acceptValue: "CIVIL" },
    ],
  },
  {
    name: "position",
    type: INPUT_TYPES.CREATABLE_SELECT,
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.POSITION.LABEL",
    label: "FORM_PDF.FORM.REPRESENTATIVE.POSITION.LABEL",
    options: [
      { value: "Zarząd", label: "Zarząd" },
      {
        value: "Przedsiębiorca lub Wspólnik",
        label: "Przedsiębiorca lub Wspólnik",
      },
      { value: "Wspólnik spółki osobowej", label: "Wspólnik spółki osobowej" },
      { value: "Pełnomocnik", label: "Pełnomocnik" },
      { value: "Prokurent", label: "Prokurent" },
    ],
  },
  // {
  //     name: 'position',
  //     type: INPUT_TYPES.TEXT,
  //     label: "FORM_PDF.FORM.REPRESENTATIVE.POSITION.LABEL"
  // },
  {
    name: "address.state",
    data: "voivodeships",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.STATE.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.STATE.LABEL",
    options: [],
    clearList: [
      "address.province",
      "address.commune",
      "address.city",
      "address.street",
    ],
  },
  {
    name: "address.province",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.PROVINCE.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.PROVINCE.LABEL",
    options: [],
    depend: true,
    url: URLS.counties,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "address.state",
      },
    ],
    dependName: "address.state",
    apiValueName: "id",
    apiLabelName: "name",
    clearList: ["address.commune", "address.city", "address.street"],
    paramsList: [
      {
        name: "address.state",
        value: "voivedeshipId",
      },
    ],
  },
  {
    name: "address.commune",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    placeholder: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    options: [],
    depend: true,
    url: URLS.communities,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "address.state",
      },
      {
        paramName: "countyId",
        dependFieldName: "address.province",
      },
    ],
    dependName: "address.province",
    apiValueName: "id",
    apiLabelName: "name",
    clearList: ["address.city", "address.street"],
    paramsList: [
      {
        name: "address.state",
        value: "voivedeshipId",
      },
      {
        name: "address.province",
        value: "countyId",
      },
    ],
  },
  {
    name: "address.city",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.CITY.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.CITY.PLACEHOLDER",
    options: [],
    depend: true,
    url: URLS.cities,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "address.state",
      },
      {
        paramName: "countyId",
        dependFieldName: "address.province",
      },
      {
        paramName: "communityId",
        dependFieldName: "address.commune",
      },
    ],
    dependName: "address.commune",
    apiValueName: "id",
    apiLabelName: "name",
    apiTypeName: "type",
    clearList: ["address.street"],
    paramsList: [
      {
        name: "address.state",
        value: "voivedeshipId",
      },
      {
        name: "address.province",
        value: "countyId",
      },
      {
        name: "address.commune",
        value: "communityId",
      },
    ],
  },
  {
    name: "address.postCode",
    type: INPUT_TYPES.POST_CODE,
    label: "FORM_PDF.FORM.REPRESENTATIVE.POST_CODE.LABEL",
  },
  {
    name: "address.street",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.STREET.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.STREET.LABEL",
    options: [],
    depend: true,
    url: URLS.streets,
    dependNames: [
      {
        paramName: "voivedeshipId",
        dependFieldName: "address.state",
      },
      {
        paramName: "countyId",
        dependFieldName: "address.province",
      },
      {
        paramName: "communityId",
        dependFieldName: "address.commune",
      },
      {
        paramName: "cityId",
        dependFieldName: "address.city",
      },
    ],
    dependName: "address.city",
    apiValueName: "id",
    apiLabelName: "name",
    paramsList: [
      {
        name: "address.state",
        value: "voivedeshipId",
      },
      {
        name: "address.province",
        value: "countyId",
      },
      {
        name: "address.commune",
        value: "communityId",
      },
      {
        name: "address.city",
        value: "cityId",
      },
    ],
  },
  {
    name: "address.houseNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.HOUSE_NUMBER.LABEL",
  },
  {
    name: "address.localNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.LOCAL_NUMBER.LABEL",
  },
];
