import { Fragment } from "react";
import { Col, FormGroup } from "reactstrap";
import { TInputProps } from "./types/inputProps";
import { useFormikContext } from "formik";
import NumberInput from "./inputs/NumberInput";
import SimpleInput from "./inputs/SimpleInput";
import PostCodeInput from "./inputs/PostCodeInput";
import PeselInput from "./inputs/PeselInput";
import SimpleInputWithButton from "./inputs/SimpleInputWithButton";
import CheckboxInput from "./inputs/CheckboxInput";
import SelectInput from "./inputs/SelectInput";
import AsyncSelectInput from "./inputs/AsyncSelectInput";
import SwitchInput from "./inputs/SwitchInput";
import TimeRangeInput from "./inputs/TimeRangeInput";
import CreatableSelectInput from "./inputs/CreatableSelectInput";
import { INPUT_TYPES } from "./helper/enums";
import { getValue } from "./helper/functions";
import Custom from "./inputs/Custom";
import DateInput from "./inputs/DateInput";
import AsyncCreatableSelectInput from "./inputs/AsyncCreatableSelectInput";
import GeoInput from "components/inputs/GeoInput";

const FormInput = (props: TInputProps) => {
  const { size, name, type, conditions } = props;
  const { values } = useFormikContext();

  const generateInput = (props: TInputProps) => {
    switch (props.type) {
      case INPUT_TYPES.TEXT:
      case INPUT_TYPES.TIME:
      case INPUT_TYPES.TEXTAREA:
      case INPUT_TYPES.FILE:
      case INPUT_TYPES.EMAIL:
        return <SimpleInput {...props} />;
      case INPUT_TYPES.DATE:
        return <DateInput {...props} />;
      case INPUT_TYPES.NUMBER:
        return <NumberInput {...props} />;
      case INPUT_TYPES.POST_CODE:
        return <PostCodeInput {...props} />;
      case INPUT_TYPES.PESEL:
        return <PeselInput {...props} />;
      case INPUT_TYPES.NUMBER_WITH_BUTTON:
        return <SimpleInputWithButton {...props} />;
      case INPUT_TYPES.CHECKBOX:
        return <CheckboxInput {...props} />;
      case INPUT_TYPES.SELECT:
        return <SelectInput {...props} />;
      case INPUT_TYPES.ASYNC_SELECT:
        return <AsyncSelectInput {...props} />;
      case INPUT_TYPES.SWITCH:
        return <SwitchInput {...props} />;
      case INPUT_TYPES.TIME_RANGE:
        return <TimeRangeInput {...props} />;
      case INPUT_TYPES.GEO:
        return <GeoInput {...props} />;
      case INPUT_TYPES.CREATABLE_SELECT:
        return <CreatableSelectInput {...props} />;
      case INPUT_TYPES.ASYNC_CREATABLE_SELECT:
        return <AsyncCreatableSelectInput {...props} />;
      case INPUT_TYPES.CUSTOM:
        return <Custom>{props.content || <div></div>}</Custom>;
      default:
        return <Fragment />;
    }
  };

  const checkConditions = () => {
    if (conditions) {
      let flag = false;
      conditions.forEach((item) => {
        const value = getValue(values, item.toCompare);
        if (typeof value === "object") {
          if (value?.value === item.acceptValue) {
            flag = true;
            return;
          }
        } else if (value?.toString() === item.acceptValue) {
          flag = true;
          return;
        }
      });
      return flag;
    } else {
      return true;
    }
  };

  return (
    <>
      {checkConditions() && (
        <Col md={size ? size : 6} key={name} className="my-auto">
          {type !== INPUT_TYPES.SWITCH && (
            <FormGroup>{generateInput(props)}</FormGroup>
          )}
          {type === INPUT_TYPES.SWITCH && generateInput(props)}
        </Col>
      )}
    </>
  );
};

export { FormInput };
