import { Tooltip } from "@mui/material";
import FormButton from "components/button/FormButton";
import { RepresentativeObject } from "config/registration-form/initial/initial";
import { FieldArrayRenderProps } from "formik";
import { useTranslation } from "react-i18next";

type AddButtonProps = {
  arrayHelpers: FieldArrayRenderProps;
  inVisible?: boolean;
  setActiveTab: Function;
  length: number;
};

const AddButton = ({
  arrayHelpers,
  inVisible,
  setActiveTab,
  length,
}: AddButtonProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!inVisible && (
        <Tooltip title={t("FORM_PDF.FORM.REPRESENTATIVE.ADD.TOOLTIP")}>
          <div className="add-button">
            <FormButton
              type="button"
              onClick={() => {
                arrayHelpers.push(RepresentativeObject);
                setActiveTab(length);
              }}
              title={t("FORM_PDF.FORM.REPRESENTATIVE.ADD.BUTTON")}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default AddButton;
