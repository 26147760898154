type TooltipProps = {
  children: JSX.Element;
  text: string;
};

const Tooltip = ({ children, text }: TooltipProps) => {
  return (
    <div className="form-tooltip">
      {children}
      <span className="form-tooltip__text">{text}</span>
    </div>
  );
};

export default Tooltip;
