import { Button, Spinner } from "reactstrap";

type FormButtonProps = {
  title: string;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean | undefined;
  color?: string;
  outline?: boolean;
  onClick?: Function;
  buttonProps?: any;
  isLoading?: boolean;
};

const FormButton = ({
  title,
  type,
  disabled,
  onClick,
  buttonProps,
  color,
  outline,
  isLoading,
}: FormButtonProps) => (
  <Button
    color={color || "primary"}
    {...buttonProps}
    disabled={disabled || isLoading}
    outline={outline}
    type={type}
    className="form-button"
    onClick={onClick && type !== "submit" ? () => onClick() : undefined}
  >
    {title}
    {isLoading && <Spinner className="ms-2" size="sm" />}
  </Button>
);
export default FormButton;
