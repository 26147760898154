import { FieldArray } from "formik";
import { Nav, NavItem, TabContent } from "reactstrap";
import RepresentativeTab from "./representatives/RepresentativeTab";
import AddButton from "./representatives/AddButton";
import RepresentativeTabContent from "./representatives/RepresentativeTabConten";
import { TOption } from "types/inputProps";

type RepresentativesProps = {
  formik: any;
  setActiveTab: Function;
  activeTab: number;
  voivodeships: TOption[];
};

const Representatives = ({
  formik,
  activeTab,
  voivodeships,
  setActiveTab,
}: RepresentativesProps) => {
  return (
    <>
      {formik.values.companyType?.value === "CIVIL" && (
        <p>
          Proszę o podanie danych wszystkich osób reprezentujących firmę,
          zgodnie ze stanem faktycznym.
        </p>
      )}
      <FieldArray
        name="representatives"
        render={(arrayHelpers: any) => {
          const representatives = formik.values.representatives || [{}];
          return (
            <div>
              {representatives.length > 1 ? (
                <Nav tabs>
                  {representatives.map((_: any, index: number) => (
                    <RepresentativeTab
                      key={index}
                      activeTab={activeTab}
                      formik={formik}
                      setActiveTab={setActiveTab}
                      index={index}
                      arrayHelpers={arrayHelpers}
                    />
                  ))}
                  <NavItem>
                    <AddButton
                      setActiveTab={setActiveTab}
                      length={representatives.length}
                      arrayHelpers={arrayHelpers}
                    />
                  </NavItem>
                </Nav>
              ) : (
                <></>
              )}
              <TabContent activeTab={activeTab}>
                {representatives.map((_: any, index: number) => (
                  <RepresentativeTabContent
                    index={index}
                    voivodeships={voivodeships}
                    key={index}
                  />
                ))}
              </TabContent>
              {formik.values.companyType?.value !== "JDG" && (
                <AddButton
                  setActiveTab={setActiveTab}
                  length={representatives.length}
                  arrayHelpers={arrayHelpers}
                  inVisible={representatives.length > 1}
                />
              )}
            </div>
          );
        }}
      />
    </>
  );
};

export default Representatives;
