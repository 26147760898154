import { useState } from "react";
import FormModal from "../form-modal/FormModal";
import axios from "axios";
import { toast } from "react-toastify";
import { URLS } from "config/registration-form/urls";
import { SUCCESS_MODAL_TEXTS } from "./texts";
import Spinner from "components/spinner/Spinner";
import { SuccessModalProps } from "./types";
import CheckIcon from "components/icons/CheckIcon";

const { EMAIL, GOOD_DAY, PDF, REF_NUMBER, SEND, THANKS, TITLE, TOAST } =
  SUCCESS_MODAL_TEXTS;

const SuccessModal = ({ applicationNumber, closeModal }: SuccessModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const getPDF = () => {
    setIsLoading(true);
    const params = new URLSearchParams();
    params.append("hash", applicationNumber);
    axios
      .get(URLS.GET_PDF, {
        responseType: "blob",
        params,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${applicationNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => toast.error(TOAST.ERROR))
      .finally(() => setIsLoading(false));
  };

  return (
    <FormModal
      isOpen={applicationNumber !== ""}
      isLoading={false}
      size="xl"
      setIsOpen={closeModal}
      title={TITLE}
      onEnter={() => {}}
    >
      <div className="success-modal">
        <div className="success-modal__number">
          <div className="success-modal__number-icon">
            <CheckIcon />
          </div>
          <div>
            <div>{SEND}</div>
            <div>
              {REF_NUMBER}
              <strong>#{applicationNumber}</strong>
            </div>
          </div>
        </div>
        <div className="success-modal__pdf">
          <div className="success-modal__pdf-title">{PDF.TITLE}</div>
          <div className="success-modal__pdf-link" onClick={() => getPDF()}>
            {PDF.FILE}
          </div>
          {isLoading && (
            <div className="success-modal__pdf-loading">
              {PDF.DOWNLOAD_IN_PROGRESS}
              <Spinner />
            </div>
          )}
          <div className="success-modal__pdf-footer">{PDF.FOOTER}</div>
          <div>{EMAIL}</div>
        </div>
        <div className="success-modal__thanks">{THANKS}</div>
        <div className="success-modal__good-day">{GOOD_DAY}</div>
      </div>
    </FormModal>
  );
};

export default SuccessModal;
