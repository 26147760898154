import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
} from "reactstrap";
import { CustomFormInput } from "helper/form";
import { useTranslation } from "react-i18next";
import { TInputProps } from "types/inputProps";

type FormCardProps = {
  children?: JSX.Element[] | JSX.Element;
  title: string;
  active: boolean;
  index: string;
  hasError: boolean;
  inputs?: TInputProps[];
  partsInput?: {
    part1: TInputProps[];
    part2: TInputProps[];
  };
  isVisible?: boolean;
  fields?: {
    api: string;
    goal: string;
  }[];
};

const FormCard = ({
  children,
  title,
  active,
  index,
  hasError,
  inputs,
  isVisible,
  partsInput,
}: FormCardProps) => {
  const { t } = useTranslation();

  const getItemClassNames = () => {
    let base = "form-card__accordion_item";
    if (active) return `${base}--active`;
    if (hasError) return `${base}--error`;
    return base;
  };

  const getHeaderClassNames = () => {
    let base = "form-card__accordion_item_header";
    if (active) return `${base}--active`;
    if (hasError) return `${base}--error`;
    return base;
  };

  const getVisibility = () => {
    if (isVisible === undefined) return true;
    return isVisible;
  };

  return (
    <>
      {getVisibility() && (
        <AccordionItem className={getItemClassNames()}>
          <AccordionHeader targetId={index} className={getHeaderClassNames()}>
            {title}
          </AccordionHeader>
          <AccordionBody accordionId={index}>
            <div className="form-card">
              <div className="form-card__content">
                {active ? (
                  <>
                    {partsInput !== undefined && (
                      <Row>
                        <Col md={6}>
                          <Row>
                            {partsInput?.part1?.map((row, index) => {
                              let tempRow = {
                                ...row,
                                label: row.label === "" ? "" : t(row.label),
                              };
                              if (tempRow.labels) {
                                tempRow = {
                                  ...tempRow,
                                  labels: t(row.labels || "") || "",
                                };
                              }
                              if (tempRow.placeholder) {
                                tempRow = {
                                  ...tempRow,
                                  placeholder: t(tempRow.placeholder) || "",
                                };
                              }
                              return (
                                <CustomFormInput key={index} {...tempRow} />
                              );
                            })}
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row>
                            {partsInput?.part2?.map((row, index) => {
                              let tempRow = {
                                ...row,
                                label: row.label === "" ? "" : t(row.label),
                              };
                              if (tempRow.labels) {
                                tempRow = {
                                  ...tempRow,
                                  labels: t(row.labels || "") || "",
                                };
                              }
                              if (tempRow.placeholder) {
                                tempRow = {
                                  ...tempRow,
                                  placeholder: t(tempRow.placeholder) || "",
                                };
                              }
                              return (
                                <CustomFormInput key={index} {...tempRow} />
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {inputs !== undefined && (
                      <Row>
                        {inputs?.map((row, index) => {
                          let tempRow = {
                            ...row,
                            label: row.label === "" ? "" : t(row.label),
                          };
                          if (tempRow.labels) {
                            tempRow = {
                              ...tempRow,
                              labels: t(row.labels || "") || "",
                            };
                          }
                          if (tempRow.placeholder) {
                            tempRow = {
                              ...tempRow,
                              placeholder: t(tempRow.placeholder) || "",
                            };
                          }
                          return <CustomFormInput key={index} {...tempRow} />;
                        })}
                      </Row>
                    )}
                    {inputs === undefined && (children ?? <></>)}
                  </>
                ) : (
                  <></>
                )}
              </div>
              {title === "Zgody" && (
                <div className="additional-info">
                  <span>
                    Zapoznaj się z dokumentami, które w kolejnym etapie należy
                    dostarczyć:
                  </span>
                  <ol>
                    <li>
                      <a
                        href="https://www.totalizator.pl/storage/files/2024/3/8/f6d1e9e9-a201-4499-961e-54cd9fd218e9/O%C5%9Bwiadczenie.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Oświadczenie o spełnieniu wymogów art. 28 ust. 3 ustawy
                        o grach hazardowych
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.totalizator.pl/storage/files/2024/3/4/e916a516-9ba0-4d5f-9fdb-6ba0107fce19/Upowa%C5%BCnienie.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Upoważnienie do weryfikacji w ewidencji udostępniającej
                        informacje i dane gospodarcze
                      </a>
                    </li>
                  </ol>
                  <span>
                    Informujemy, że powinny one zostać podpisane przez
                    uprawnione osoby podpisem odręcznym lub podpisem
                    kwalifikowanym pod rygorem nieważności.
                  </span>
                  <span>
                    Po złożeniu formularza zgłoszeniowego prześlemy szczegóły
                    dotyczące dostarczenia dokumentów.
                  </span>
                </div>
              )}
            </div>
          </AccordionBody>
        </AccordionItem>
      )}
    </>
  );
};

export default FormCard;
