import React from "react";
import { FastField, Field } from "formik";
import { getError } from "../validators";
import { Input, Label } from "reactstrap";
import { TInputProps } from "../types/inputProps";

const CheckboxInput = (props: TInputProps) => {
  const { name, disabled, label, labels, depend, type, shouldUpdate } = props;

  const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {};

  if (depend) {
    return (
      <Field
        name={name}
        label={label}
        labels={labels}
        type={type}
        id={name}
        {...shoudUpdateProp}
        component={CustomSwitchInput}
        disabled={disabled}
      />
    );
  } else {
    return (
      <FastField
        name={name}
        label={label}
        labels={labels}
        type={type}
        id={name}
        {...shoudUpdateProp}
        component={CustomSwitchInput}
        disabled={disabled}
      />
    );
  }
};

export default CheckboxInput;

const getDinamicData = (item: string, values: any) => {
  let array = item.split("$");
  let tempItem = item;
  array.forEach((abc) => {
    switch (abc.split(" ")[0]) {
      case "FULL_COMPNANY_NAME":
        tempItem = tempItem.replace(
          "$FULL_COMPNANY_NAME",
          values.companyName || ""
        );
        break;
    }
  });
  return tempItem;
};

const CustomSwitchInput = ({
  field,
  form: { touched, errors, values },
  ...props
}: any) => {
  const getLabel = () => {
    if (props.label.includes("<link>")) {
      const linkParts = props.label.split("<link>");
      return (
        <div>
          {linkParts[0]}
          <a
            href="https://www.totalizator.pl/storage/files/2024/3/4/b4393144-a415-42e7-90cd-2582980b465f/Pakiet%20informacyjny%20dla%20oferent%C3%B3w.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkParts[1]}
          </a>
          {linkParts[2]}
        </div>
      );
    } else {
      return props.label;
    }
  };

  return (
    <div>
      <Input
        style={
          getError(field, touched, errors)
            ? { width: "20px", height: "20px", borderColor: "red" }
            : { width: "20px", height: "20px" }
        }
        {...field}
        {...props}
      />
      <Label
        className="sfa-form__label"
        htmlFor={field.name}
        style={{ width: "calc(100% - 50px)" }}
      >
        {props.labels ? (
          <div>
            {props.label.split("<br />").map((item: any, index: number) => (
              <span key={index}>
                {getDinamicData(item, values)}
                <br />
              </span>
            ))}
            <ol>
              {props.labels.split(";").map((item: string, index: number) => (
                <li key={index}>
                  {item.split("|")[0]}
                  <ul>
                    {item
                      .split("|")
                      .filter((_i, index) => index !== 0)
                      .map((subItem, subIndex) => (
                        <li key={subIndex}>
                          {subItem.split("<br />").map((value, index) => (
                            <span key={index}>
                              {value}
                              <br />
                            </span>
                          ))}
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <div className="d-flex">{getLabel()}</div>
        )}
      </Label>
    </div>
  );
};
