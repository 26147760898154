export const SUCCESS_MODAL_TEXTS = {
  TITLE: "Wniosek został poprawnie zapisany",
  SEND: "Dziękujemy za wysłanie swojego wniosku!",
  REF_NUMBER:
    "Potwierdzamy, że otrzymaliśmy go i przypisalismy numer referencyjny: ",
  PDF: {
    TITLE:
      "Możesz pobrać potwierdzenie wniosku w formacie PDF, klikając na poniższy link:",
    FILE: "Wniosek w formacie PDF.",
    FOOTER:
      "Prosimy pamiętać, że będziesz musiał/a mieć zainstalowany program do czytania plików PDF na swoim urządzeniu.",
    DOWNLOAD_IN_PROGRESS: "Trwa pobieranie pliku...",
  },
  THANKS:
    "Dziękujemy jeszcze raz za złożenie wniosku. Doceniamy Twoje zaufanie i postaramy się jak najszybciej odpowiedzieć na Twoje zgłoszenie. Jeśli będziesz potrzebować dalszej pomocy, nie wahaj się skontaktować z nami.",
  GOOD_DAY: "Życzymy miłego dnia!",
  EMAIL:
    "Na dalszym etapie przetwarzania, wszelkie niezbędne dokumenty zostaną przesłane na adres e-mail podany w formularzu. Prosimy o sprawdzenie skrzynki odbiorczej oraz folderu ze spamem.",
  TOAST: {
    ERROR: "Nie udało się pobrać pliku",
  },
};
