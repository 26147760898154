const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2919 7.29504L11.9999 10.587L8.70795 7.29504L7.29395 8.70904L10.5859 12.001L7.29395 15.293L8.70795 16.707L11.9999 13.415L15.2919 16.707L16.7059 15.293L13.4139 12.001L16.7059 8.70904L15.2919 7.29504Z"
        fill="#AAAAAA"
      />
      <path
        d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default CloseIcon;
