import { Modal } from "react-bootstrap";
import { TFormModal } from "./types";
import Spinner from "components/spinner/Spinner";
import { FORM_MODAL_TEXTS } from "./texts";
import CloseIcon from "components/icons/CloseIcon";

const { LOADING, PLEASE_WAIT } = FORM_MODAL_TEXTS;

const FormModal = ({
  children,
  isOpen,
  setIsOpen,
  title,
  onEnter,
  isLoading,
  size,
}: TFormModal) => {
  return (
    <Modal
      size={size}
      centered
      show={isOpen}
      onHide={() => setIsOpen(!isOpen)}
      onEnter={() => onEnter()}
    >
      <Modal.Body>
        <div className="form-modal__header">
          <div className="form-modal__header-title">{title}</div>
          <div
            className="form-modal__header-close cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="form-modal__body">
          {isLoading ? (
            <div>
              <div className="text-center my-2">{LOADING}</div>
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
              <div className="text-center my-2">{PLEASE_WAIT}</div>
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default FormModal;
