import { useState } from "react";
import { Tooltip } from "@mui/material";
import { ErrorMessage, FastField, Field } from "formik";
import { getError } from "helper/validators";
import { Input, Label, Spinner } from "reactstrap";

import { Trans } from "react-i18next";
import { TInputProps } from "types/inputProps";
import axios from "axios";
import { toast } from "react-toastify";
import { URLS } from "config/registration-form/urls";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const SimpleInputWithButton = ({
  name,
  type,
  disabled,
  label,
  depend,
  hidden,
  fields,
  requestLimit,
  text,
  url,
  fieldsToTouch,
}: TInputProps) => {
  return (
    <div className="w-100">
      <Label className="sfa-form__label">{label}</Label>
      {depend ? (
        <Field
          component={CustomSimpleInputWithButton}
          name={name}
          fields={fields}
          text={text}
          url={url}
          fieldsToTouch={fieldsToTouch}
          requestLimit={requestLimit}
          disabled={disabled}
          type={hidden ? "hidden" : type}
        />
      ) : (
        <FastField
          component={CustomSimpleInputWithButton}
          name={name}
          fields={fields}
          url={url}
          text={text}
          fieldsToTouch={fieldsToTouch}
          requestLimit={requestLimit}
          disabled={disabled}
          type={hidden ? "hidden" : type}
        />
      )}
    </div>
  );
};

export default SimpleInputWithButton;

const CustomSimpleInputWithButton = ({
  field,
  form: {
    values,
    touched,
    errors,
    setFieldValue,
    validateForm,
    setFieldTouched,
  },
  ...props
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const setData = async () => {
    setIsLoading(true);
    const type = values.companyType?.value === "JDG" ? "F" : "P";
    let token = "";
    if (executeRecaptcha) {
      token = await executeRecaptcha("getCompoantData");
    }
    axios
      .get(`${props.url}${field?.value}`, { headers: { recaptcha: token } })
      .then((res) => {
        if (res.data[0].type !== type) {
          toast.error(
            "UWAGA! Pobrane dane na podstawie numeru NIP posiadają inny typ działalności, niż podana forma działalności w formularzu. Należy ponownie wybrać formę działalności w pierwszej sekcji."
          );
          setFieldValue("companyType", null);
        }
        const data = {
          voivodeshipName: res.data[0].voivodeship,
          countyName: res.data[0].county,
          communeName: res.data[0].commune,
          cityName: res.data[0].city,
          streetName: res.data[0].street,
        };
        axios
          .post(
            data.streetName !== ""
              ? URLS.verificationWithStreet
              : URLS.verification,
            { ...data }
          )
          .then((response) => {
            setFieldValue("registeredAddress.state", {
              label: response.data.value[0].voivodeshipName,
              value: response.data.value[0].voivodeshipId,
            });
            setFieldValue("registeredAddress.province", {
              label: response.data.value[0].countyName,
              value: response.data.value[0].countyId,
            });
            setFieldValue("registeredAddress.commune", {
              label: response.data.value[0].communeName,
              value: `${response.data.value[0].communeId}||${response.data.value[0].communeType}`,
            });
            setFieldValue("registeredAddress.city", {
              label: response.data.value[0].cityName,
              value: response.data.value[0].cityId,
            });
            if (response.data.value[0].streetName) {
              setFieldValue("registeredAddress.street", {
                label: response.data.value[0].streetName,
                value: response.data.value[0].streetId,
              });
            }
            setFieldValue("regon", res.data[0].regon);
            setFieldValue("companyName", res.data[0].companyName);
            setFieldValue("registeredAddress.postCode", res.data[0].zipCode);
            setFieldValue(
              "registeredAddress.houseNumber",
              res.data[0].houseNumber
            );
            setFieldValue(
              "registeredAddress.localNumber",
              res.data[0].flatNumber
            );
            setFieldValue("krs", res.data[0].krs);
            setFieldValue("court", res.data[0].districtCourt);
            setFieldValue(
              "courtDepartment",
              res.data[0].districtCourtDepartment
            );
            setTimeout(() => {
              validateForm();
            }, 1000);
          })
          .catch(() => {
            if (counter + 1 < 3) {
              toast.error("Nie udało się pobrać danych o firmie.");
            } else {
              toast.error(
                "Nie udało się pobrać danych o firmie na podstawie NIP, funkcjonalność została zablokowana. Wpisz dane ręcznie."
              );
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response?.status === 429) {
          toast.error(error.response.data.message);
        } else {
          setTimeout(() => {}, 1000);
          toast.error("Nie udało się pobrać danych o firmie.");
        }
      })
      .finally(() => {
        if (props.fieldsToTouch) {
          props.fieldsToTouch?.forEach((item: string) => {
            setFieldTouched(item);
          });
        }

        setCounter(counter + 1);
      });
  };

  const isButtonEnable = () => {
    return (
      errors[field.name] === undefined &&
      field.value !== "" &&
      counter < props.requestLimit
    );
  };

  const getButtonClassname = () => {
    if (isButtonEnable()) {
      return "btn btn-primary d-flex align-items-center";
    } else {
      return "btn btn-primary disabled d-flex align-items-center";
    }
  };

  const getTooltipTextWhenFieldValueIsCorrect = () => {
    return counter < props.requestLimit ? (
      <Trans>{props.text.tooltipIfEnable}</Trans>
    ) : (
      <Trans>{props.text.tooltipIfRequestLimit}</Trans>
    );
  };

  return (
    <div
      className={
        getError(field, touched, errors) ? "sfa-form__input--error" : ""
      }
    >
      <div className="d-flex">
        <Input {...field} />
        <div>
          <Tooltip
            title={
              errors[field.name] === undefined && field.value !== "" ? (
                getTooltipTextWhenFieldValueIsCorrect()
              ) : (
                <Trans>{props.text.tooltipIfDisabled}</Trans>
              )
            }
          >
            <div>
              <div onClick={() => setData()} className={getButtonClassname()}>
                <Trans>{props.text.button}</Trans>
                {isLoading && <Spinner className="ms-2" size={"sm"} />}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="sfa-form__error">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};
