import { useState } from "react";
import GeoModal from "components/modals/geo-modal/GeoModal";
import { Field, useFormikContext } from "formik";
import { getValue } from "helper/functions";
import { INPUT_TYPES } from "helper/enums";
import GeoIcon from "components/icons/GeoIcon";
import { TInputProps } from "modules/form-generator/types/inputProps";
import { TSingleDataForm } from "components/modals/geo-modal/types";

const GeoInput = (props: TInputProps) => {
  const [isGeoModalOpen, setIsGeoModalOpen] = useState(false);
  const { setFieldValue, validateForm, values } = useFormikContext();

  const saveData = (values: TSingleDataForm[]) => {
    values.forEach((item) => {
      setFieldValue(item.name, item.value);
    });
    setTimeout(() => {
      validateForm();
    }, 1000);
  };

  const getAddress = () => {
    if (Array.isArray(props.data)) {
      const data = {
        state: getValue(values, "point.address.state"),
        county: getValue(values, "point.address.province"),
        commune: getValue(values, "point.address.commune"),
        city: getValue(values, "point.address.city"),
        street: getValue(values, "point.address.street"),
        houseNumber: getValue(values, "point.address.houseNumber"),
        postalcode: getValue(values, "point.address.postCode"),
      };
      return data;
    }
    return null;
  };

  return (
    <div className="d-flex">
      <Field
        className={"form-control"}
        name={props.name}
        type={INPUT_TYPES.TEXT}
        hidden={false}
        disabled={true}
      />
      <div
        className="ms-3 p-2 d-flex align-items-center cursor-pointer"
        onClick={() => setIsGeoModalOpen(true)}
      >
        <GeoIcon />
      </div>
      <GeoModal
        isOpen={isGeoModalOpen}
        setIsOpen={() => setIsGeoModalOpen(false)}
        saveData={saveData}
        address={getAddress()}
      />
    </div>
  );
};

export default GeoInput;
