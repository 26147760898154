import React, { Fragment } from "react";
import { ErrorMessage, FastField, Field } from "formik";
import ReactSelect from "react-select";
import { Label } from "reactstrap";
import { useEffect, useState, useCallback } from "react";
import { getError } from "../validators";
import { TDependName, TInputProps, TOption } from "../types/inputProps";
import { getValueByPath } from "../helper/getValueByPath";
import { getRequest } from "../helper/apiCaller";

type TLoadItem = {
  name: string;
  value: string;
};

const AsyncSelectInput = (props: TInputProps) => {
  const {
    name,
    disabled,
    placeholder,
    label,
    depend,
    url,
    dependName,
    dependNames,
    apiValueName,
    apiLabelName,
    options,
    clearList,
    shouldUpdate,
    paramsList,
  } = props;

  const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {};

  if (depend) {
    return (
      <Field
        name={name}
        disabled={disabled}
        label={label}
        options={options || []}
        paramsList={paramsList || []}
        dependName={dependName || ""}
        dependNames={dependNames || []}
        apiValueName={apiValueName || ""}
        apiLabelName={apiLabelName || ""}
        url={url || ""}
        component={CustomAsyncSelectInput}
        clearList={clearList || []}
        {...shoudUpdateProp}
        placeholder={placeholder}
      />
    );
  } else {
    return (
      <FastField
        name={name}
        disabled={disabled}
        label={label}
        options={options || []}
        paramsList={paramsList || []}
        dependName={dependName || ""}
        dependNames={dependNames || []}
        apiValueName={apiValueName || ""}
        apiLabelName={apiLabelName || ""}
        url={url || ""}
        component={CustomAsyncSelectInput}
        clearList={clearList || []}
        {...shoudUpdateProp}
        placeholder={placeholder}
      />
    );
  }
};

export default AsyncSelectInput;

const CustomAsyncSelectInput = React.memo(
  ({
    field,
    form: { touched, errors, setFieldValue, values, setFieldTouched },
    ...props
  }: any) => {
    const [options, setOptions] = useState(props.options);
    const [isLoading, setIsLoading] = useState(false);
    const [prevValue, setPrevValue] = useState<string>("");
    const [altOptions, setAltOptions] = useState<TOption[]>([]);

    const prepareUrlParams = (items?: TLoadItem[]) => {
      const urlParams = new URLSearchParams();
      props.paramsList.forEach((item: any) => {
        const urlItem = items?.find((i) => i.name === item.value);
        if (urlItem) {
          if (urlItem.name === "communityId") {
            urlParams.append("communeId", urlItem.value.split("||")[0]);
            urlParams.append("communeType", urlItem.value.split("||")[1]);
          } else {
            urlParams.append(urlItem.name, urlItem.value);
          }
        } else {
          urlParams.append(
            item.value,
            getValueByPath(values, item.name)?.value
          );
        }
      });
      return urlParams;
    };

    const loadOptions = useCallback(
      (items?: TLoadItem[]) => {
        const preparedParams = prepareUrlParams(items);
        setIsLoading(true);
        if (props.apiLabelName === "") {
          setOptions(props.options);
          setIsLoading(false);
        } else if (preparedParams.toString().includes("undefined")) {
          setIsLoading(false);
        } else {
          getRequest({
            url: props.url,
            urlParams: preparedParams,
            then: (data: any) => {
              let options: any = [];
              data.forEach((item: any) => {
                const type = item.type;
                const additionalInfo = item.additionalInfo;
                options.push({
                  label: `${item[props.apiLabelName]}${
                    additionalInfo ? ` (${additionalInfo})` : ""
                  }`,
                  value: `${item[props.apiValueName]}${
                    type ? `||${type}` : ""
                  }`,
                });
              });
              if (options.length > 200) {
                let selectedOption = undefined;
                if (field.value) {
                  selectedOption = options.find((option: TOption) =>
                    option.label
                      .toLowerCase()
                      .includes(field.value.label.split(" ")[0].toLowerCase())
                  );
                }
                if (selectedOption) {
                  setAltOptions([selectedOption, ...options.slice(0, 200)]);
                } else {
                  setAltOptions(options.slice(0, 200));
                }
              }
              setOptions(options);
            },
            onFinnaly: () => {
              setIsLoading(false);
            },
          });
        }
      },
      [props.url, props.apiValueName, props.apiLabelName, props.options]
    );

    const getDependValue = useCallback(() => {
      let pool: string[] = [props.dependName];
      if (props.dependName.includes(".")) pool = props.dependName.split(".");
      let inputValue = values;
      pool.forEach((item) => {
        inputValue = inputValue?.[item];
      });
      return inputValue;
    }, [props.dependName, values]);

    const getLastLabel = (paramName: string) => {
      return paramName.split(".").pop() || "";
    };

    const prepaprePrevValueBasedOnDependNames = () => {
      let value = "";
      props.dependNames.forEach(({ dependFieldName }: TDependName) => {
        value = `${value}_${getValueByPath(values, dependFieldName)?.value}`;
      });
      return value;
    };

    const getValueFromNestedObject = (obj: any, path: string) => {
      return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    };

    useEffect(() => {
      const preparedPrevValue = prepaprePrevValueBasedOnDependNames();
      if (
        getValueByPath(values, props.dependName)?.value &&
        prevValue !== preparedPrevValue
      ) {
        const loadItems: TLoadItem[] = [];
        props.dependNames.forEach(
          ({ paramName, dependFieldName }: TDependName) => {
            const isNested = dependFieldName.includes(".");
            let value;

            if (isNested) {
              value = getValueByPath(values, dependFieldName);
            } else {
              value = values[dependFieldName];
            }

            // Sprawdzamy, czy wartość istnieje i ładowanie opcji
            if (value) {
              if (isNested && prevValue !== value.value) {
                setPrevValue(value.value);
              }
              loadItems.push({
                name: getLastLabel(paramName),
                value: value.value,
              });
            }
          }
        );
        loadOptions(loadItems);
        setPrevValue(preparedPrevValue);
      }
    }, [
      ...props.dependNames.map(
        ({ dependFieldName }: { dependFieldName: string }) =>
          dependFieldName.includes(".")
            ? getValueFromNestedObject(values, dependFieldName)
            : values[dependFieldName]
      ),
    ]);

    const handleInput = (value: string) => {
      if (options.length > 200) {
        setTimeout(() => {
          const newAltOptions = options.filter((option: TOption) =>
            option.label.toLowerCase().includes(value.toLowerCase())
          );
          setAltOptions(newAltOptions.slice(0, 200));
        }, 1000);
      }
    };

    return (
      <div>
        {props.label !== "" ? (
          <Label className="sfa-form__label">
            <div className="d-flex">
              {props.label}
              {options.length > 200 &&
                " (Wyświetlone zostało maksymalnie 200 wyników, aby zawęzić wyniki wyszukiwania wpisz więcej znaków.)"}
            </div>
          </Label>
        ) : (
          <></>
        )}
        <ReactSelect
          {...field}
          placeholder={props.placeholder}
          options={options.length > 200 ? altOptions : options}
          //options={options}
          noOptionsMessage={({ inputValue }) =>
            !inputValue
              ? "Brak dostępnych elementów"
              : "Brak pasujących elementów"
          }
          onBlur={() =>
            setTimeout(() => {
              setFieldTouched(field.name);
            }, 100)
          }
          isLoading={isLoading}
          isDisabled={props.dependName ? !getDependValue() || isLoading : false}
          value={
            field.value
              ? options.find(
                  (option: TOption) =>
                    option.value === field.value?.value?.trim().toLowerCase()
                ) ||
                options.find((option: TOption) =>
                  option.label
                    .trim()
                    .toLowerCase()
                    .includes(field.value?.label?.trim().toLowerCase())
                ) ||
                null
              : field.value
          }
          onInputChange={handleInput}
          onChange={(option: any) => {
            props.clearList.forEach((item: string) => {
              if (item.includes("|string")) {
                setFieldValue(item.split("|")[0], "");
              } else {
                setFieldValue(item, null);
              }
            });
            setFieldValue(field.name, {
              label: option.label,
              value: option.value,
            });
          }}
          className={
            getError(field, touched, errors)
              ? "react-select-container sfa-form__input--error"
              : "react-select-container"
          }
          classNamePrefix="react-select"
        />
        <div className="sfa-form__error">
          <ErrorMessage name={field.name} />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    const prevDependValue = getValueByPath(
      prevProps.form.values,
      prevProps.dependName
    );
    const nextDependValue = getValueByPath(
      nextProps.form.values,
      nextProps.dependName
    );

    const prevFieldValue = prevProps.field.value?.value;
    const nextFieldValue = nextProps.field.value?.value;

    const prevError = getValueByPath(
      prevProps.form.errors,
      prevProps.field.name
    );
    const nextError = getValueByPath(
      nextProps.form.errors,
      nextProps.field.name
    );

    return (
      prevDependValue === nextDependValue &&
      prevFieldValue === nextFieldValue &&
      prevError === nextError
    );
  }
);
