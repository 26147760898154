import axios from "axios";
import { toast } from "react-toastify";

type TRequestProps = {
  url: string;
  param?: any;
  then: Function;
  onFinnaly?: Function;
  onCatch?: Function;
  urlParams?: URLSearchParams;
};

export const getRequest = async ({
  url,
  param,
  then,
  onFinnaly,
  onCatch,
  urlParams,
}: TRequestProps) => {
  const getParams = () => {
    if (urlParams) {
      return {
        params: urlParams,
      };
    } else if (param) {
      return {
        param,
      };
    } else {
      return {};
    }
  };

  await axios
    .get(url, getParams())
    .then((response) => {
      then(response.data.value);
    })
    .catch(() => {
      if (onCatch) {
        onCatch();
      } else {
        toast.error("Wystąpił błąd podczas pobierania danych");
      }
    })
    .finally(() => {
      if (onFinnaly) {
        onFinnaly();
      }
    });
};
